/* Global settings */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
}

.home-page {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: #000;
  font-family: 'Inter', sans-serif;
}

/* Navbar and logo overlayed on top of the video */
.hamburger-menu {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
}

.menu-icon {
  cursor: pointer;
  color: #fff;
}

.sidebar1 {
  position: fixed;
  top: 0;
  right: -100%;
  width: 200px;
  height: 100%;
  background-color: #BBADA0;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease-in-out;
  z-index: 100;
  opacity: 95%;
}

.sidebar1.open {
  right: 0;
  z-index: 100;
}

.sidebar-menu {
  list-style: none;
  padding: 20px;
  margin: 0;
}

.sidebar-menu li {
  margin: 20px 0;
  font-weight: bold;
}

.sidebar-menu a {
  text-decoration: none;
  font-size: 18px;
  color: #000;
  display: block;
  padding: 10px;
}

.home-page__nav {
  position: absolute;
  top: 2rem;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 10;
  width: 100%;
  font-weight: bold;
}
.enc_prod_nav{
  position: absolute;
  top: 2rem;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 10;
  width: 100%;
  font-weight: bold;
}

.enc_prod_nav ul{
  list-style: none;
  display: flex;
  gap: 5rem;
}
.enc_prod_nav ul li a {
  text-decoration: none;
  color: black;
  font-size: 1.2rem;
  font-weight: bold;
}

.home-page__nav ul {
  list-style: none;
  display: flex;
  gap: 5rem;
}

.home-page__logo {
  max-height: 16rem; /* Adjust height as needed */
  transform: translateY( -6rem);
  z-index: 1;
  align-items: center;
  justify-content: center;
}



.home-page__nav ul li a {
  text-decoration: none;
  color: #fff;
  font-size: 1.2rem;
}
.home-page__nav ul li a:hover{
  text-decoration: underline;
  text-shadow: #BBADA0;
  cursor: pointer;
  

}

.home-page_bgvid {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
  position: relative;
}
/* .gtku{
  margin-top: 5rem;
} */
.gtku img{
  width: 100%;
  height: 70rem;
  object-fit: cover;
}

/* Get to Know Us section placed below video */
.home-page__getto-knowus {
  text-align: center;
  position: relative;
  z-index: 1;
  background-image: url('../data/Pattern.png');
  max-height: 50rem;
  transform: translateY(-3rem);
}

.home-page__getto-knowus h1 {
  font-size: 3.5rem;
  padding-left: 5%;
  display: flex;
  padding-top: 2%;
}

/* Container for aligning image and text content side-by-side */
.content-container {
  display: flex;
  align-items: flex-start;
}

/* Styling for the logo image */
.logo_png {
  max-width: 30rem;
  height: 35rem;
  border-radius: 10px;
  display: flex;
  margin-left: 67rem;
  transform: translateY(-32rem);
}

/* Text container beside the image */
.text-content {
  max-width: 70rem;
  padding-left: 5%;
  font-size: 1.8rem;
  justify-content: left;
}
.text-content p{
  display: flex;
  text-align: left;
}

/* Styling for p2 underneath the image and text content */
.italic {
  font-size: 2.5rem;
  text-align: center;
  display: block;
  font-style: italic;
  font-weight: bolder;
  padding: 5%;
  transform: translateY(-35rem);
}
.card_container {
  text-align: left;
  background-color: #BBADA0;
  padding: 2%;
  /* margin-top: 3rem; */
  /* transform: translateY(-40rem); */

}

.card_container h1 {
  font-size: 3.0rem;
  display: flex;
  margin-bottom: 20px;
  margin-left: 5%;
}

.card_container p {
  font-size: 2.0rem;
  margin-bottom: 20px;
  margin-left: 5%;
}

.card_holder {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
  flex-wrap: wrap;
  background-color: #BBADA0;
  padding: 2%;
}
.card_holder a{
  text-decoration: none;
}

.card {
  background-color: #46413C;
  z-index: 10;
  border-radius: 2rem;
  width: 20rem;
  height: 28rem;
  text-align: center;
  overflow: hidden;
  padding: 1.5%;
}
.card:hover{
  background-color: black;
  transform: scale(1.05); /* Optional: Slight zoom effect on hover */

}
.card a{
  text-decoration: none;
}

.card img {
  width: 100%;
  height: 19rem;
  width: 17rem;
  object-fit: cover;
  border-radius: 2rem;
  border: 0.5rem white solid;
  margin-top: 1rem;
}

.card-content {
  text-decoration: none; /* Removes the underline */
  color: white;
}

.card-title {
  font-size: 1.6rem;
  margin-top: 1.6rem;
}

/* Container setup */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;

}

/* Align left and right sections side by side */
.left-side,
.right-side {
  width: 50%; /* Each side takes up 50% of the screen */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.right-side{
  background-color: #BBADA0;
  gap: 3rem;
  transform: translate(2rem, -2rem);
  text-align: left;
  padding-top: 6%;
  padding-left: 7%;
  font-size: 1.5rem;
  padding-bottom: 5%;
  text-decoration: underline white;
  font-weight: bold;
}
.right-side a{
  text-decoration: none;
}
.left-side{
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}
/*foo starts here*/

.container_foo {
  display: flex;
  width: 100%;
  justify-content: space-between; /* Space out the sides if needed */
  margin-top: 3rem;
}

.left-side_foo, .right-side_foo {
  width: 50%; /* Each side takes up 50% of the screen */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.left-side_foo {
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  transform: translateY(-2rem);
}

.right-side_foo {
  background-color: #BBADA0;
  gap: 3rem;
  transform: translate(2rem, -2rem);
  text-align: left;
  padding-top: 2%;
  padding-left: 7%;
  font-size: 1.5rem;
  padding-bottom: 2%;
  text-decoration: underline white;
  font-weight: bolder;
}
.right-side_foo a{
  text-decoration: none;
  transform: translateY(1.5rem);
}

/* FOOO1 ENDS hereeeee */

h1 {
  font-size: 2em;
}

p1, p2, p3, p4, p5, p6, p7, p8, p9 {
  font-size: 1em;
}
.logo-end{
  height: 15rem;
  width: 15rem;
  display: flex;
  align-items: center;
  background-color: #BBADA0;
  border-radius: 2rem;
  padding-left: 6%;
  padding-right: 6%;
}
.left-side h1{
  font-size: 2rem;
  color: black;
  width: max-content;
  font-weight: bold;
  margin-top: 5rem;
}
.right-side p1, .right-side p2,.right-side p3, .right-side p4, .right-side p5, .right-side p6, .right-side p7, .right-side p8, .right-side p9{
  color: white;
}
/*foo2 starts here*/
/* .h1 {
  font-size: 2em;
  margin-bottom: 1em;
} */

.right-side_foo p, .right-side_foo p2, .right-side_foo p3, .right-side_foo p4, .right-side_foo p5, .right-side_foo p6, .right-side_foo p7, .right-side_foo p8, .right-side_foo p9 {
  font-size: 1em;
  margin-bottom: 10px;
  color: white;
}
.logo-end_foo{
  height: 15rem;
  width: 15rem;
  display: flex;
  align-items: center;
  background-color: #BBADA0;
  border-radius: 2rem;
  padding-left: 6%;
  padding-right: 6%;
}

.left-side_foo h1{
  font-size: 2rem;
  color: black;
  width: max-content;
}
.footer_foo{
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
/* .right-side_foo p, .right-side_foo p2,.right-side_foo p3, .right-side_foo p4, .right-side_foo p5, .right-side_foo p6, .right-side_foo p7, .right-side_foo p8, .right-side_foo p9{
  color: white;
} */
/* FOOooo2 ENDS */
.icons{
  display: flex;
  flex-direction: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 2.5rem;
  gap: 2.5rem;
}
.icons a{
  text-decoration: none;
  color: black;
}

.icons svg{
  width: 3.5rem;
  height:3.5rem;
}

.bold{
  font-weight: bold;
  font-size: 1.2rem;
}
.footer{
  /* transform: translateY(-40rem); */
  overflow: hidden;
  margin-bottom: 2rem;
}


.first {
  background-color: antiquewhite;
  height: 35px;
  width: 150px; /* Increased width */
  text-align: center;
  border-radius: 10px;
  line-height: 35px;
  font-weight: bold;
  font-family: Arial, sans-serif;
  white-space: nowrap; /* Prevents text wrapping */
  padding: 0 10px; /* Optional: Add padding for extra space */
  transition: background-color 0.3s;
}

.first:hover {
  background-color: #d4c2a8;
  cursor: pointer;
}
/* Our tech css */

.banner img{
  max-height: 38rem;
  width: 100%;
  margin-top: 9rem;
}
.bottom-banner img{
  max-height: 22rem;
  width: 100%;  
}
.alt_logo{
  max-height: 12rem; /* Adjust height as needed */
  transform: translateY( -4rem);
  z-index: 1;
  align-items: center;
  justify-content: center;
}
.heading{
 margin-top: 8rem;
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
}
.heading p{
  font-size: 1.7rem;
}
.heading h1{
  font-size: 3rem;
  align-items: center;
}

.card_container_op{
  text-align: center;
  background-color: #BBADA0;
  padding: 2%;
  width: 100%;
}
.card_holder_op{
  display: flex;
  justify-content: space-around;
  gap: 1.5rem;
  flex-wrap: wrap;
  background-color: #BBADA0;
  padding: 2%;
}
.card_holder_op a{
  text-decoration: none;
}
.card_op{
  background-color: #46413C;
  z-index: 10;
  display: flex;
  align-items: center;
  border-radius: 2rem;
  width: 20rem;
  height: 28rem;
  text-align: center;
  flex-direction: column;
  overflow: hidden;
  padding: 7.5%;
}
.card_op:hover{
  background-color: black;
  transform: scale(1.05); /* Optional: Slight zoom effect on hover */
}
.card_op img {
  height: 20rem;
  object-fit: cover;
  border-radius: 2rem;
  border: 0.5rem white solid;
  width: 15rem;
}
.op_content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.op_content h1{
  font-size: 3rem;
}
.tech-content{
  display: flex;
  padding-top: 1rem;
  align-items: center;
 justify-content: center;
}
.tech-content img{
  max-height: 112rem;
  padding: 0%;
  display: flex;
  object-fit: contain;
}
.pro-content img{
  max-height: 25rem;
  padding: 0%;
  display: flex;
  width: 100%;
  object-fit: cover;
  border-radius: 2rem;
  border: 0.5rem white solid;
  margin-top: 1rem;
}
/* about us pageeeeeeeeeeeeeeeeeeeeeeee */
.cu-banner img{
  max-height: 25rem;
  padding: 0%;
  display: flex;
  width: 100%;
  object-fit: cover;
  border-radius: 2rem;
  border: 0.5rem white solid;
}
.cu-heading {
  margin-top: 9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.cu-heading p{
  font-size: 1.3rem;
  padding-left: 15%;
  padding-right: 15%;
}
.cu-heading h1{
  font-size: 3rem;
  align-items: center;
}
.cu-para {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.cu-para p{
  font-size: 2rem;
  padding-left: 5%;
  padding-right: 5%;
  font-weight: 400;
}



.image-gallery {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
}

.image-item {
  flex: 1;
  max-width: 30%; /* Ensures images don't overflow and fit side by side */
  text-align: center;
  cursor: pointer;
  position: relative;
}

.gallery-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.gallery-image:hover {
  transform: scale(1.05); /* Optional: Slight zoom effect on hover */
}

h3 {
  font-size: 1.2em;
  margin-top: 10px;
}

.dropdown {
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: 90%;
  font-size: 0.9em;
  display: block;
}

/* blog page */
/* blog page */

.bpg_content{
  display: flex;
  flex-direction: wrap;
  justify-content: center;
  align-items: center;
}
.card_bpg img{
max-height: 15rem;
max-width: 12rem;
}
@media (max-width: 768px) {
  .image-item {
    max-width: 100%; /* Stacks images vertically on smaller screens */
    margin-bottom: 20px;
  }

  .image-gallery {
    flex-direction: column;
    align-items: center;
  }
}

/* Contact Section */


.contact-section h2 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #5c524a;
}

.contact-section h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.contact-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 20px; 
  
}

 .map iframe {
  height: 350px;
  width: 400px;
  margin-left: 200px;
} 

/* Contact Form Styling */
/* ContactUs.css */
.contact-us-page {
  display: flex;
  flex-direction: row;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 12rem;
  background-image: url('../data/Pattern.png');
}

.contact-us-map {
  flex: 1;
  margin-right: 2rem;
}

.contact-us-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-us-form h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
}

.contact-us-form form {
  display: flex;
  flex-direction: column;
}

.contact-us-form label {
  margin-bottom: 10px;
  font-weight: bold;
}

.contact-us-form input, .contact-us-form textarea {
  margin-bottom: 20px;
  padding: 10px;
  font-size: 1.2rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #D9D9D9;
}

.contact-us-form textarea {
  height: 5rem;
  resize: none;
}

.contact-us-form button {
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.contact-us-form button:hover {
  background-color: #45a049;
}
.cf_head h1{
  display: flex;
  justify-content: center;
  transform: translateY(11rem);
  font-size: 3rem;
}

/*Encapsulated product page*/

.products_section {
  padding: 1rem;
  margin-top: 8%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.products_section h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 50px;
  margin-top: 50px;
}

.products_grid {
  display: grid;
  gap: 2rem;
  column-gap: 9rem;
  background-color: white;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 2fr));
  align-items: center;
  justify-content: space-around;
}

/* Individual Product Styles */
.product {
  background-color: #f9f9f9;
  border-radius: 20px;
  overflow: hidden;
  text-align: center;
  height: 20rem;
  width: 28rem;
  gap: 2rem;
}
.product:hover{
  transform: scale(1.15); /* Optional: Slight zoom effect on hover */

}

.product img {
  width: 27rem;
  height: 16rem;
  display: block;
  margin: 0 auto 10px;
  border-radius: 20px;
}

.product p {
  margin: 0;
  font-size: 1.4rem;
  color: #333;
  background-color: #BBADA0;
  padding: 1.5%;
  border-radius: 5px;
}
/* Media Query for Mobile Devices */
/* @media (max-width: 419px) {
  .nav-left {
    display: none;
  }
  .nav-right {
    display: none;
  }
  .home-page__logo{
    display: flex;
    justify-content: center;
    transform: translate(-30rem, -2rem);
    max-height: 6rem;
  }
  .right-side{
    transform: translate(0rem, -8rem);
  }
    
} */
/* @media (max-width: 767px) {
  .nav-left {
    display: none;
  }
  .nav-right {
    display: none;
  }
  .home-page__logo{
    display: flex;
    justify-content: center;
    transform: translate(17rem, -2rem);
    max-height: 10rem;
  }
} */
/* @media (max-width: 1800px) {
  .home-page__nav{
    display: flex;
    justify-content: center;
    transform: translateX(-19rem);

  }

} */
@media (min-width: 768px) {
  .container {
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
  }

  .footer {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 20px;
    margin-top: 20px;
    text-align: center;
    border-top: 1px solid #ccc;
  }

  .footer p7,
  .footer p8 {
    margin: 0 5px;
  }
  .hamburger-menu{
    display: none;
  }
}

/* Hide the burger menu on desktop devices */
@media (max-width: 800px) {
  .text-content{
    font-size: 1.0rem;
    display: block;
    align-self: left;
    width: 75%;
    transform: translate(-1rem, 0rem);
  }
  .logo_png {
    display: flex;
    transform: translate(-42rem, -15rem);
    max-height: 22rem;
    max-width: 14rem;
  }
  .last-para{
    transform: translateY(-20rem);

  }
  .italic{
    font-size: 1.5rem;
    transform: translateY(-20rem);
  }
  .home-page__getto-knowus{
    height: max-content;
  }
  .card_container h1{
    font-size: 2rem;
  }
  .card_container{
    transform: translate(0rem, -20rem);
  }
  .bar{
    width: 10rem;
    transform: translate(0rem, -18rem);

  }
  .card_container p{
    font-size: 1.4rem;
  }
  .card{
    max-height: 18rem;
    max-width: 12rem;
    padding: 5%;
  }
  .card img{
    max-height: 14rem;
    max-width: 11rem;
    border: 8px solid white;
  }
  .card-title{
    font-size: 1.4rem;
    font-weight: bold;
  }
  .card_holder{
    gap: 2rem;
  }
  .container{
    transform: translateY(-21rem);
  }
  .footer{
    transform: translateY(-25rem);
  }
  /* .left-side h1{
    font-size: 1.5rem;
    max-width: 10rem;
    transform: translateX(3rem);
  }
  .right-side h1{
    font-size: 1.5rem;
    max-width: 10rem;
    transform: translate(-1rem, 0.7rem);

  }
  .right-side p, p2, p3, p4, p5, p6, p7, p8, p9{
    font-size: 0.7rem;
    transform: translateX(-0.5rem);
  }
  .right-side{
    transform: translate(12.2rem, -12.2rem);
    gap: 1.3rem;
    justify-content: center;
    align-items: center;
    width: 23rem;
  }
  .logo-end{
    max-width: 8rem;
    max-height: 8rem;
  }
  .left-side{
    transform: translate(-11rem, 18rem);
  }
  .left-side p, p2, p3, p4, p5, p6{
    font-size: 0.9rem;
  }
  .bold{
    font-size: 1rem;
  }
  .footer{
    transform: translate(19rem, -50rem);
  } */
  .container{
    overflow: hidden;
  }

}
  
@media (max-width: 1600px) {

}
@media (max-width: 1350px) {
  .left-side h1{
    font-size: 3rem;
    max-width: 100rem;
    transform: translateX(-2rem);
  }
  .right-side h1{
    font-size: 3rem;
    max-width: 100rem;
    transform: translate(2.8rem, 0.8rem);
  }

}
@media (max-width: 1200px) {
  .home-page__getto-knowus h1{
    font-size: 1.7rem;
  }
  .card{
    width: 22rem;
  }
  .card img{
    width: 19rem;
  }
  .first {
    background-color: antiquewhite;
    height: 35px;
    width: 150px; /* Increased width */
    text-align: center;
    border-radius: 10px;
    line-height: 35px;
    font-weight: bold;
    font-family: Arial, sans-serif;
    white-space: nowrap; /* Prevents text wrapping */
    padding: 0 10px; /* Optional: Add padding for extra space */
    transition: background-color 0.3s;
  }
  
  .first:hover {
    background-color: #d4c2a8;
    cursor: pointer;
  }
  .left-side h1{
    font-size: 2.8rem;
    max-width: 10rem;
    transform: translateX(-2.2rem);
  }
  .right-side h1{
    font-size: 2.8rem;
    max-width: 10rem;
    transform: translate(0rem, 0.8rem);
  }
}
@media (max-width: 950px) {
  .left-side h1{
    font-size: 2.8rem;
    max-width: 10rem;
    transform: translateX(-3.5rem);
  }
  .right-side h1{
    font-size: 2.8rem;
    max-width: 10rem;
    transform: translate(1rem, 0.8rem);
  }
  .card{
    width: 18rem;
    height: 25rem;
  }
  .card img{
    width: 15rem;
    height: 17rem;
  }

}
@media (max-width: 460px) {
  .home-page__getto-knowus h1{
    font-size: 1.7rem;
  }
  .text-content{
    font-size: 1.0rem;
    display: block;
    align-self: left;
    width: 75%;
    transform: translate(-1rem, 0rem);
  }
  .logo_png {
    display: flex;
    transform: translate(-62rem, -18rem);
    max-height: 22rem;
    max-width: 14rem;
  }
  .last-para{
    transform: translateY(-20rem);

  }
  .italic{
    font-size: 1.5rem;
    transform: translateY(-20rem);
  }
  .home-page__getto-knowus{
    height: max-content;
  }
  .card_container h1{
    font-size: 2rem;
  }
  .card_container{
    transform: translate(0rem, -20rem);
  }
  .bar{
    width: 10rem;
    transform: translate(0rem, -18rem);

  }
  .card_container p{
    font-size: 1.4rem;
    padding: 2%;
  }
  .card{
    max-height: 20rem;
    max-width: 8.5rem;
    padding: 4%;
  }
  .card img{
    max-height: 14rem;
    max-width: 7rem;
    border: 8px solid white;
  }
  .card-title{
    font-size: 1.1rem;
    font-weight: bold;
  }
  .card_holder{
    gap: 1rem;
  }
  .left-side h1{
    font-size: 1.5rem;
    max-width: 10rem;
    transform: translateX(-2rem);
  }
  .right-side h1{
    font-size: 1.5rem;
    max-width: 10rem;
    transform: translate(3.5rem, 0.8rem);

  }
  .right-side p, p2, p3, p4, p5, p6, p7, p8, p9{
    font-size: 0.7rem;
    transform: translateX(-0.5rem);
  }
  .right-side{
    transform: translate(7rem, -14.5rem);
    gap: 1.3rem;
    justify-content: center;
    align-items: center;
    width: 15rem;
  }
  .logo-end{
    max-width: 8rem;
    max-height: 8rem;
  }
  .left-side{
    transform: translate(-7rem, 18rem);
  }
  .left-side p, p2, p3, p4, p5, p6{
    font-size: 0.9rem;
  }
  .left-side p6{
    transform: translateX(1rem);
  }
  .bold{
    font-size: 1rem;
  }
  .footer{
    transform: translate(8rem, -53rem);
  }
  .container{
    overflow: hidden;
    transform: translate(1rem, -38.5rem);
  }
  
}

@media (max-width: 408px) {
  .right-side{
    transform: translate(6.2rem, -16rem);
  }
  .left-side{
    transform: translate(-6rem, 17.5rem);
  }
  .left-side h1{
    transform: translateX(-3rem);
  }
  .logo-end{
    transform: translateX(-1.1rem);
    max-width: 7rem;
  }
  .left-side p6{
    transform: translateX(0rem);
  }

}
